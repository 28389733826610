import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Charijicontent from "../components/subpages/charijicontent";

import Charijibreadcreumb from "../components/subpages/charijibreadcumb";

// markup
const charijiPage = () => {
  return (
    <div>
      <Seo title="chariji" />

      <Layout isSticky>
        <section>
          <Charijibreadcreumb/>
        </section>

        <section>
          <Charijicontent />
        </section>
      </Layout>
    </div>
  );
};

export default charijiPage;
