import React from "react";
import { Link } from "gatsby";
export default function Charijicontent() {
  return (
    <section className="charijicontentpage pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 className="theme-h4 theme-color"> Chariji Maharaj </h4>
            <p className="theme-p-tag"> 1927 A.D – 2014 A.D.</p>
            <p className="theme-p-tag">
              {" "}
              Shri Parthasarathi Rajagopalachari, also known as Chariji, was
              born in Vayalur (near Chennai) in the south of India.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Chariji’s conscious spiritual aspirations were awakened at the age
              of eighteen when he heard a lecture on the Bhagavad Gita, but he
              continued with his studies, and graduated from Benares Hindu
              University with a Bachelor of Science degree. His first job was in
              chemical engineering after which he spent two years in Yugoslavia
              studying plastics manufacturing techniques.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              He married in 1955 and joined the T. T. Krishnamachari group of
              companies the same year, soon rising to the position of executive
              director to one of the companies in the group. His work required
              him to travel extensively in India and abroad, establishing a
              pattern of world travel that he continued throughout his life,
              both professionally and then in his role as a Heartfulness guide.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              In 1964 Chariji was introduced to the Heartfulness practice and
              several months later met Babuji. From his first meeting with
              Babuji, as he wrote in his book, My Master, “I knew immediately
              and intuitively that I had found the person who alone could be my
              Master and lead me to my goal.”
            </p>
            <p className="theme-p-tag">
              {" "}
              While continuing to fulfill his familial and business
              responsibilities, Chariji was assiduous in his spiritual practice
              and fervent in his devotion towards Babuji. He dedicated himself
              to helping Babuji actualize his vision for humanity.
            </p>
            <p className="theme-p-tag">
              {" "}
              Chariji accompanied Babuji on extensive travels throughout India.
              Following the arrival in India of Europeans drawn to Babuji and
              his teachings, Chariji in 1972 accompanied Babuji on the first of
              a series of overseas journeys to Europe and America. They met
              aspirants, conducted meditations, gave talks, answered questions.
              A relationship of loving interdependence grew between them.
            </p>
            <p className="theme-p-tag">
              {" "}
              During his lifetime, Babuji nominated Chariji as his successor and
              in 1983 when Babuji passed on, Chariji took over as his spiritual
              representative. He dedicated himself to continuing the work Babuji
              had started and furthering his vision for humanity. Under his
              leadership the organisation Babuji had set up proliferated both in
              India and to more than one hundred other countries. At the time of
              Chariji’s death membership had increased from 5,000 – as it had
              been in Babuji’s time – to 500,000. It has always been founded on
              volunteer work and funded by donations.{" "}
            </p>
            <p className="theme-p-tag">
              {" "}
              Chariji proved himself to be what Babuji had defined as the ideal
              human – a Western mind with an Eastern heart.{" "}
            </p>
            <p className="theme-p-tag"> As well as being a brilliant orator, his capacity to work seemed almost superhuman. In spite of the numbers of people who came to see him and whom he met in the course of his travels, he was renowned for his willingness to engage with individuals who came to him freely. Tens of thousands of individuals have stories of one-to-one meetings with him that transformed their lives. </p>
            <p className="theme-p-tag"> He wrote and published over one hundred books. His book, My Master, a tribute to his beloved Babuji, has been translated into 20 languages. Other books include diaries of his overseas travels with Babuji, an autobiography and numerous volumes of his talks in India and abroad representing and amplifying his Master’s teaching. </p>
            <p className="theme-p-tag"> From 2008, Chariji suffered from ill-health and in 2010, Chariji used the technology of the day to let all his followers know that Kamlesh D. Patel was to be his successor on his passing. This was his way of ensuring that right from the start, there would be full support for him in what had become an enormous task. From this day, until his eventual passing, they were nearly always together. </p>
            <p className="theme-p-tag"> Chariji passed away on 20th December 2014, passing on the role of the global Heartfulness guide to Shri Kamlesh D. Patel, affectionately known as  <Link   to="https://heartfulness.org/en/chariji/"
                target="_blank"
                rel="noreferrer" className="text-decore-none"
              >
                <span className="orange-text"> Daaji. </span>
              </Link>{" "} </p>
          </div>
        </div>
      </div>
    </section>
  );
}
